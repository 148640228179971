<template>
    <div class="wrap">
        <div class="main_title">Dashboard</div>
        <div class="content">
            <a-spin :spinning="statSpinning">
                <div class="statistics flex">
                    <div class="w25 flex_box" :key="idx" v-for="(value, idx) in statistics">
                        <div class="flex">
                            <div class="icon">
                            </div>
                            <div class="info flex_column">
                                <div class="value">{{value.value}}</div>
                                <div class="name">{{value.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </a-spin>
            <div class="latest_orders">
                <div class="sub_title">Latest orders</div>
                <OrderComponents :isLatest="true" :isShowQuery="false" :isShowPagination="false" />
            </div>
        </div>
    </div>
</template>
<script>
import { getOrderStatistics } from '@/apis/order'
import OrderComponents from '@/components/admin/order/order'
export default {
    name: 'Dashboard',
    components: {
        OrderComponents
    },
    data(){
        return {
            statistics: {
                today: {
                    name: 'Today orders',
                    value: 0
                },
                total: {
                    name: 'Total orders',
                    value: 0
                },
                finished: {
                    name: 'Finished orders',
                    value: 0
                },
                income: {
                    name: 'General income',
                    value: 0
                },
            },
            orderList: [],
            statSpinning: false
        }
    },
    methods: {
        /**
         * 获取统计数据
         */
        async getOrderStatisticsHandler(){
            this.statSpinning = true
            const res = await getOrderStatistics()
            this.statSpinning = false
            this.statistics.today.value = res.today
            this.statistics.total.value = res.total_orders
            this.statistics.finished.value = res.total_finished
            this.statistics.income.value = res.income
        },
    },
    created(){
        this.getOrderStatisticsHandler()
    }
}
</script>
<style lang="stylus" scoped>
.flex_box
    margin 10px
    background #fff
    padding 20px
    cursor pointer
    transition .5s
    &:hover
        box-shadow 0 10px 20px 0 rgba(0,0,0,.1)
.statistics 
    justify-content space-between
    .name
       color #abb1bf
    .value
       font-size 22px
.latest_orders
    margin-top 20px
</style>